@import 'src/styles/colors';

.title {
  display: contents !important;
  vertical-align: middle;
  white-space: normal;
  word-break: break-word;
}

.fieldWithIcon {
  display: 'inline-block';
  margin-right: 10px;
  vertical-align: 'middle';
}

.e-grid .e-rowcell {
  white-space: pre-wrap !important;
  word-break: break-word;
}

.e-switch-on,
span.e-switch-handle.e-switch-active {
  background: #8a85ff !important;
}
.light {
  .e-grid {
    td.e-active {
      background: $light-selected;
      &:hover {
        background: $light-selected !important;
      }
    }
  }

  // collapse
  .e-treegrid .e-treegridexpand,
  .e-treegrid .e-treegridcollapse {
    color: $light-text-primary;
  }
  .e-treegrid .e-treegridexpand:hover::before,
  .e-treegrid .e-treegridcollapse:hover::before {
    color: $light-text-primary !important;
  }
}

.one_dark {
  // collapse
  .e-treegrid .e-treegridexpand,
  .e-treegrid .e-treegridcollapse {
    color: #fff;
  }
  .e-treegrid .e-treegridexpand:hover::before,
  .e-treegrid .e-treegridcollapse:hover::before {
    color: #fff !important;
  }
}

.e-rowcell .e-treecolumn-container span.e-icons.e-none {
  width: 20px !important;
}

// https://ej2.syncfusion.com/react/documentation/grid/how-to/grid-styling/
@import 'src/styles/colors';

.e-ccdlg {
  width: auto !important;
  right: 0;
  left: auto !important;
}

.e-toolbar-item {
  .e-expand::before {
    content: '\e556';
  }

  .e-collapse::before {
    content: '\e554';
  }

  /*.e-indent::before {
    content: '\e33f';
  }

  .e-outdent::before {
    content: '\e35d';
  }*/
}

// LIGHT THEME
.light {
  .e-headercelldiv {
    .e-headertext {
      color: $light-text-primary !important;
    }
  }
  .e-grid {
    background: $light-background !important;
    color: $light-text-primary !important;
    border: none;
    border-radius: 4px;
    //box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.7), 0 3px 4px -2px rgba(0, 0, 0, 0.5);

    .e-headercell,
    .e-table {
      background: $light-background !important;
      color: $light-text-primary !important;
    }
    .e-input e-search {
      border: 1px solid transparent;
      color: $light-text-primary !important;
    }
    .e-grid th.e-headercell[aria-sort='ascending'] .e-headertext,
    .e-grid th.e-headercell[aria-sort='descending'] .e-headertext,
    .e-grid th.e-headercell[aria-sort='ascending'] .e-sortfilterdiv,
    .e-grid th.e-headercell[aria-sort='descending'] .e-sortfilterdiv {
      color: $light-text-primary !important;
    }
    .e-droppable e-draggable {
      background: $light-primary !important;
    }

    .e-rowcell.e-focus {
      box-shadow: 1px 1px 1px transparent;
    }
    .e-rowcell.e-gridchkbox-cell {
      .e-checkbox-wrapper {
        .e-check {
          background: $light-background !important;
          color: $light-text-primary !important;
        }
      }
    }

    .e-rowcell:not(.e-editedbatchcell) {
      color: inherit;
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
    }
    .e-row:hover .e-rowcell {
      background: $light-selected !important;
      color: $light-text-primary !important;
    }
    &.e-gridhover
      tr[role='row']:not(.e-editedrow):hover
      .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(
        .e-updatedtd
      ):not(.e-indentcell) {
      color: inherit;
      background: rgba(0, 0, 0, 0.04);
    }
    td.e-active {
      background: $light-selected;
      &:hover {
        background: $light-selected !important;
      }
    }
    // search input
    .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
      border: 1px solid #bbbdbe !important;
      &:hover {
        border: 1px solid $light-text-primary;
      }
      .e-input {
        color: $light-text-primary !important;
        border: 1px solid transparent !important;
      }
    }

    .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search.e-input-focus {
      border: 2px solid $light-primary;
      &:hover {
        border: 2px solid $light-primary;
      }
    }

    /*[class^='e-'] {
      color: inherit !important;
      border-color: #515151;
    }*/

    // column drop area
    .e-groupdroparea {
      color: #fff !important;
      background: $light-primary !important;
      border: none;
    }

    td.e-updatedtd {
      background: $light-background;
      color: $light-text-primary;
    }
  }

  // switch colors
  .e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
  .e-css.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active {
    background: $light-primary !important;
  }

  .e-switch-wrapper.e-rtl .e-switch-on,
  .e-css.e-switch-wrapper.e-rtl .e-switch-on {
    background: $light-primary !important;
  }

  .e-switch-wrapper.e-rtl .e-switch-off,
  .e-css.e-switch-wrapper.e-rtl .e-switch-off {
    background: #bebebe !important;
  }

  .e-switch-wrapper.e-rtl .e-switch-handle,
  .e-css.e-switch-wrapper.e-rtl .e-switch-handle {
    background: #bebebe !important;
  }

  .e-pager .e-currentitem,
  .e-pager .e-currentitem:hover {
    color: #fff !important;
    background: $light-primary !important;
  }
  //column Menu
  .e-popup-open {
    .e-btn.e-flat.e-primary,
    .e-css.e-btn.e-flat.e-primary {
      color: $light-primary !important;
      &:hover {
        background-color: $light-selected;
      }
    }
    .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
    .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after {
      background: $light-primary !important;
    }
    .e-checkbox-wrapper {
      .e-check {
        background: $light-background !important;
        color: $light-text-primary !important;
      }
    }
  }
}

// ONE DARK THEME
.one_dark {
  .e-headertext {
    color: #fff !important;
  }
  .e-grid {
    background: $onedark-background;
    color: $onedark-text-primary;
    border: none;
    border-radius: 4px;
    //box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.7), 0 3px 4px -2px rgba(0, 0, 0, 0.5);
    .e-headercell,
    .e-table {
      background: $onedark-background;
      color: $onedark-text-primary;
    }
    .e-input e-search {
      border: 1px solid transparent;
    }
    .e-grid th.e-headercell[aria-sort='ascending'] .e-headertext,
    .e-grid th.e-headercell[aria-sort='descending'] .e-headertext,
    .e-grid th.e-headercell[aria-sort='ascending'] .e-sortfilterdiv,
    .e-grid th.e-headercell[aria-sort='descending'] .e-sortfilterdiv {
      color: $onedark-text-primary;
    }
    .e-rowcell:not(.e-editedbatchcell) {
      color: inherit;
      border-color: #515151;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
        color: $onedark-text-primary;
      }
    }
    .e-row:hover .e-rowcell {
      background-color: rgba(0, 0, 0, 0.04) !important;
      color: $onedark-text-primary !important;
    }
    &.e-gridhover
      tr[role='row']:not(.e-editedrow):hover
      .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(
        .e-updatedtd
      ):not(.e-indentcell) {
      color: inherit;
      background: rgba(255, 255, 255, 0.04);
    }

    td.e-active {
      background: $onedark-selected;
      &:hover {
        background: $onedark-selected !important;
      }
    }
    .e-rowcell.e-focus {
      box-shadow: 1px 1px 1px transparent;
    }
    .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search.e-input-focus {
      border: 2px solid $onedark-primary;
      &:hover {
        border: 2px solid $onedark-primary;
      }
    }

    .e-toolbar-items
      .e-toolbar-item.e-search-wrapper
      .e-search
      input::placeholder {
      color: #fff;
    }

    // column drop area
    .e-groupdroparea {
      background: $onedark-background !important;
      border: none;
    }

    // search input
    .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
      &:hover {
        border: 1px solid $onedark-text-primary;
      }
    }
    .e-toolbar-item .e-input-group {
      border: 1px solid #5a5d63;
    }
    td.e-updatedtd {
      background: $onedark-background;
      color: $onedark-text-primary;
    }
  }

  // switch colors
  .e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
  .e-css.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active {
    background: $onedark-primary !important;
  }

  .e-switch-wrapper.e-rtl .e-switch-on,
  .e-css.e-switch-wrapper.e-rtl .e-switch-on {
    background: $onedark-primary !important;
  }

  .e-switch-wrapper.e-rtl .e-switch-off,
  .e-css.e-switch-wrapper.e-rtl .e-switch-off {
    background: #fff !important;
  }

  .e-switch-wrapper.e-rtl .e-switch-handle,
  .e-css.e-switch-wrapper.e-rtl .e-switch-handle {
    background: #fff !important;
  }

  .e-grid .e-row .e-input-group .e-input.e-field,
  .e-grid .e-row .e-input-focus .e-input.e-field {
    color: #fff;
  }
  // pager
  .e-pager .e-currentitem,
  .e-pager .e-currentitem:hover {
    background: $onedark-primary !important;
  }

  // popin
  .e-dialog .e-dlg-content,
  .e-dialog .e-footer-content,
  .e-popup.e-popup-open.e-dialog,
  .e-grid .e-ccdlg .e-footer-content,
  .e-grid .e-ccdlg .e-dlg-content,
  .e-dialog .e-dlg-header-content {
    background: $onedark-background-popup;
  }
  .e-dialog .e-dlg-content,
  .e-grid .e-ccdlg .e-cc.e-input,
  .e-grid .e-ccdlg .e-cc.e-input:focus,
  .e-grid .e-ccdlg .e-checkbox-wrapper .e-label,
  .e-grid .e-ccsearch-icon::before,
  .e-grid-menu .e-ccsearch-icon::before,
  .e-dialog .e-footer-content .e-btn,
  .e-checkbox-wrapper .e-label,
  .e-css.e-checkbox-wrapper .e-label,
  .e-cc .e-nmatch,
  .e-dialog .e-dlg-header,
  input.e-ccsearch::placeholder {
    color: #fff;
  }
  .e-btn.e-flat.e-primary {
    color: $onedark-primary;
  }
  .e-popup .e-checkbox-wrapper .e-frame,
  .e-popup .e-css.e-checkbox-wrapper .e-frame .e-pager .e-currentitem {
    background: $onedark-primary !important;
  }
  .e-btn.e-flat.e-primary:focus,
  .e-btn.e-flat.e-primary:hover,
  .e-btn.e-flat.e-primary:active {
    background: $onedark-selected;
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
      .e-float-input
    )::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
      .e-float-input
    )::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
    .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
    .e-input-in-wrap::after {
    background: $onedark-primary;
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(
      .e-float-icon-left
    ),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(
      .e-error
    ):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(
      .e-warning
    ):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(
      .e-warning
    ):not(.e-error)
    .e-input-in-wrap {
    border-color: rgba(#fff, 0.8);
  }

  .e-input-group,
  .e-input-group.e-control-wrapper,
  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    color: inherit;
  }

  .e-dropdown-popup,
  .e-contextmenu-wrapper ul {
    background: $onedark-background-popup;
    color: $onedark-text-primary;
    .e-item:hover,
    .e-focused,
    .e-selected {
      background: $onedark-selected;
      color: inherit;
    }
    .e-menu-icon,
    .e-menu-icon:before,
    .e-icons,
    .e-icons:before {
      color: $onedark-text-primary;
    }
    .e-disabled {
      color: darken($onedark-text-primary, 40%);
    }
  }
}

// Common to every theme
.e-grid {
  .e-emptyrow:not(.e-editedbatchcell),
  .e-groupcaption,
  .e-groupcaption .e-groupdroparea,
  .e-grouptopleftcell,
  .e-icons,
  .e-indentcell,
  .e-pager,
  .e-pagercontainer,
  .e-recordplusexpand,
  .e-recordpluscollapse,
  .e-toolbar,
  .e-toolbar [class^='e-'],
  .e-toolbar-items {
    border-color: inherit;
    color: inherit !important;
    background: inherit !important;
  }
}

.e-grid .e-row,
.e-grid .e-columnheader,
.e-grid .e-toolbar-items {
  height: 60px;
}

.e-grid .e-headertext,
.e-grid .e-rowcell {
  font-size: 16px;
}

// vertical borders
.e-grid.e-resize-lines .e-headercell .e-rhandler,
.e-grid.e-resize-lines .e-headercell .e-rsuppress {
  border: none;
}

.e-grid .e-pager {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.e-grid .e-groupdroparea {
  color: inherit;
  border: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

// search input
.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
  padding: 0 14px;
  border-radius: 4px;
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search::before,
.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search::after {
  display: none;
}

.e-groupcaption {
  font-size: 18px !important;
  font-weight: bold;
}

// fixed header datagrid
.e-sidebar-context {
  //overflow-x: unset;
}

.e-grid .e-gridheader {
  position: -webkit-sticky;
  position: sticky;
  top: 60px !important;
  z-index: 1;
  overflow-y: auto;
}
.e-grid .e-toolbar {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px !important;
  z-index: 1 !important;
}

.one_dark .e-grid .e-content,
.one_dark .e-grid .e-gridheader {
  background: #282c34;
}

@import 'src/styles/colors';

.variable-width .slick-slide p {
  background: #00558b;
  height: 100px;
  color: #fff;
  margin: 5px;
  line-height: 100px;
  text-align: center;
}
.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}
.center h3 {
  opacity: 0.8;
  transition: all 300ms ease;
}
.content {
  padding: 20px;
  margin: auto;
}
@media (min-width: 701px) {
  .content {
    width: 80%;
  }
}
@media (max-width: 700px) {
  .content {
    width: 70%;
  }
}
.slick-slide .image {
  padding: 10px;
}
.slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}
.slick-slide img.slick-loading {
  border: 0;
}
.slick-slider {
  margin: 10px auto 15px;
}
.slick-list {
  max-height: 400px;
}
.slick-dots {
  margin-left: 0;
}
.slick-thumb {
  bottom: -45px;
}
.slick-thumb li {
  width: 60px;
  height: 45px;
}
.slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
@media (max-width: 768px) {
  h3 {
    font-size: 24px;
  }
  .center {
    margin-left: -40px;
    margin-right: -40px;
  }
  .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1);
  }
  .center h3 {
    opacity: 0.8;
    transform: scale(0.95);
    transition: all 300ms ease;
  }
}
.slick-vertical .slick-slide {
  height: 180px;
}
.slick-prev,
.slick-next
{
  z-index: 1;
}
.slick-prev:before,
.slick-next:before {
  color: #999;
  font-size: 40px;
}
.slick-prev {
  left: 0px;
}
.slick-next {
  right: 20px;
}
.slick-prev:focus:before,
.slick-next:focus:before
{
  opacity: 0.75;
}
.slick-prev:hover:before,
.slick-next:hover:before
{
  opacity: 1;
}

.button {
  background-color: #00558b;
  padding: 10px 20px;
  margin: 0px 20px;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  min-height: 45px;
}
.Media {
  background-size: contain;
  height: 400px;

  .noneUrl {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    align-content: center;

    & > .MuiSvgIcon-root {
      display: block;
      margin: auto;
      font-size: 60px;
    }
  }

  .MediaCaption {
    text-overflow: ellipsis;
    //position: absolute;
    //bottom: 0;
    padding: 10px;
    //margin-top: 350px;
    background-color: #000;
    //background-color: rgb(0,0,0,0.5)
    color: #fff;
    opacity: .6;
    width: 100%;
    height: 50px;
    font-size: 20px;
    font-weight: 200;
    transition: .3s;
    cursor: pointer;
  }
}
.one_dark {
  .slick-dots li button:before {
    color: #ffffff;
  }
}

/* LIGHT THEME */
$light-background: #fff;
$light-primary: #0f529f;
$light-primary-hover: lighten($light-primary, 20%);
$light-text-primary: #263238;
$light-text-secondary: #546e7a;
$light-selected: rgba(88, 80, 236, 0.08);

/* ONE DARK THEME */
$onedark-background: #282c34;
$onedark-background-popup: lighten($onedark-background, 10%);
$onedark-primary: #8a85ff;
$onedark-primary-hover: lighten($onedark-primary, 10%);
$onedark-text-primary: #e6e5e8;
$onedark-text-secondary: #adb0bb;
$onedark-selected: rgba(138, 133, 255, 0.16);

/*css workflow*/
/*.diagram-container {
  width: 100%;
}*/

$in-color: #ff0000;
$out-color: #008000;

.custom-node {
  border: solid 2px gray;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.custom-node.selected {
  font-weight: bold;
  border: solid 3px #000000;
}

.custom-node .port {
  position: absolute;
  left: calc(50% - 7px);
}

.custom-node .port[data-name='in'] {
  top: -8px;
}
.custom-node .port[data-name='out'] {
  bottom: -8px;
}

.circle-port {
  width: 12px;
  height: 12px;
  margin: 2px;
  border-radius: 6px;
  background: #a9a9a9;
  cursor: pointer;
}

.custom-node .port[data-name='in'] .circle-port {
  background-color: $in-color;
}
.custom-node .port[data-name='out'] .circle-port {
  background-color: $out-color;
}

.custom-link.in-selected path {
  stroke: $in-color;
}
.custom-link.out-selected path {
  stroke: $out-color;
}

.custom-link.in-selected circle,
.custom-link.in-selected polygon {
  fill: $in-color;
}
.custom-link.out-selected circle,
.custom-link.out-selected polygon {
  fill: $out-color;
}

.custom-node .port .circle-port:hover {
  background: #9370db;
}

.custom-node:hover,
.custom-node:focus {
  border-color: #000000;
}
.custom-node.searched {
  border: 5px solid $in-color;
}

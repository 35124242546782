// custom theme for react-sortable-tree
@import 'src/styles/colors';

.rst__rowTitle {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.one_dark {
  .rst__rowContents {
    color: $onedark-text-primary;
    background: $onedark-background;
  }

  .rst__moveHandle,
  .rst__loadingHandle,
  .rst__lineChildren::after,
  .rst__lineHalfHorizontalRight::before,
  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after {
    background-color: rgba($onedark-primary, 0.5);
  }
}

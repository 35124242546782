@import '@syncfusion/ej2-base/styles/material.css';
@import '@syncfusion/ej2-buttons/styles/material.css';
@import '@syncfusion/ej2-calendars/styles/material.css';
@import '@syncfusion/ej2-dropdowns/styles/material.css';
@import '@syncfusion/ej2-inputs/styles/material.css';
@import '@syncfusion/ej2-navigations/styles/material.css';
@import '@syncfusion/ej2-popups/styles/material.css';
@import '@syncfusion/ej2-react-grids/styles/material.css';
@import '@syncfusion/ej2-react-treegrid/styles/material.css';
@import '@syncfusion/ej2-richtexteditor/styles/material.css';
@import '@syncfusion/ej2-splitbuttons/styles/material.css';
@import 'react-perfect-scrollbar/dist/css/styles.css';
@import 'react-sortable-tree/style.css';
@import 'src/components/Datagrid/DataGrid.scss';
@import 'src/components/UI/TreeGrid/TreeGridComponent.scss';
@import 'src/components/workflow/Workflow.scss';
@import 'src/styles/react-sortable-tree/custom.scss';
@import 'src/styles/styles.css';
@import 'src/styles/customButton/style.scss';

body {
  font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', 'sans-serif',
    '-apple-system', 'BlinkMacSystemFont';
}

/*@import '../../node_modules/@syncfusion/ej2/material.css';*/

html,
body,
#root,
.makeStyles-root-1,
main,
.full-height {
  height: 100%;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

/*  preview data panel  */
.gc-layout-card {
  padding: 0 20px 20px 20px;
}

.gc-preview-grid {
  padding-left: 20px;
  /*margin-bottom: 20px !important;*/
}

.gc-list-save-button span {
  font-size: small;
}

@media only screen and (max-width: 901px) {
  .gc-list-save-button span {
    margin: 0;
  }
  .gc-list-save-button-label {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .gc-preview-grid {
    margin-bottom: 0 !important;
    top: 11px;
    z-index: 1300;
    display: block;
    position: absolute;
    width: 100%;
    max-width: calc(100% - 15px) !important;
    left: 8px;
    padding: 0;

    /*box-shadow: 0 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);*/
    box-shadow: 0 0 170px -10px rgba(0, 0, 0, 0.2);
  }

  .gc-preview-grid.category-edition {
    height: calc(100% - 11px);
  }

  .gc-preview-grid.category-edition > div {
    height: 100%;
  }
}

.gc-list-translastions-table td {
  padding-bottom: 0;
  border: none;
}

.MuiGrid-item.MuiGrid-grid-xs-12 textarea {
  width: 100%;
}

/* css dashboard  */
.dashboard-elements .MuiPaper-root {
  height: 100%;
}

.dashboard-elements .MuiCardContent-root:last-child {
  padding-bottom: 16px;
}

.warning {
  color: #f44336 !important;
}

.warning.MuiLinearProgress-colorPrimary {
  background-color: rgba(244, 67, 54, 0.3);
}
.warning .MuiLinearProgress-barColorPrimary {
  background-color: #f44336;
}

.ps__rail-y {
  z-index: 2;
}

.e-icons.e-send::before {
  content: '\e816';
}

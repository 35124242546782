@import 'src/styles/colors';
$workflow-svg: '<circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M13 6h3a2 2 0 0 1 2 2v7"></path><line x1="6" y1="9" x2="6" y2="21"></line>';
$edit-svg: '<path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>';
$duplicate-svg: '<path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>';
$external-link-svg: '<path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line>';
$delete-svg: '<path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>';
$add-svg: '<path d="M12 6C12.5523 6 13 6.44772 13 7V11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H11V7C11 6.44772 11.4477 6 12 6Z" />';
$settings-svg: '<circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>';

@function encodecolor($string) {
  @if type-of($string) == 'color' {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote('#{$hex}');
  }
  $string: '%23' + $string;
  @return $string;
}

@function coloring($type, $color) {
  $result: '';
  @if $type== 'fill' {
    $result: 'fill="#{encodecolor($color)}" stroke="none"';
  } @else {
    $result: 'fill="none" stroke="#{encodecolor($color)}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"';
  }
  @return $result;
}

@mixin inline-svg($color, $svg-content, $type) {
  $start: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 -2 23 28" #{coloring($type, $color)}>';
  $end: '</svg>';
  content: url('data:image/svg+xml;utf8, #{$start}#{$svg-content}#{$end}');
}

.workflowButton {
  padding: 10px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: $light-primary;
  width: 44px;
  height: 44px;
}

.workflowButton:before {
  @include inline-svg(#ffffff, $workflow-svg, 'stroke');
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.editButton {
  padding: 10px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: $light-primary;
  width: 44px;
  height: 44px;
}

.editButton:before {
  @include inline-svg(#ffffff, $edit-svg, 'fill');
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.editButton2 {
  padding: 10px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: $light-primary;
  width: 44px;
  height: 44px;
  left: 100%;
}

.editButton2:before {
  @include inline-svg(#ffffff, $edit-svg, 'fill');
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.addButton {
  padding: 10px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: $light-primary;
  width: 44px;
  height: 44px;
}

.addButton:before {
  @include inline-svg(#ffffff, $add-svg, 'fill');
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
}

.duplicateButton {
  padding: 10px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: $light-primary;
  width: 44px;
  height: 44px;
}

.duplicateButton:before {
  @include inline-svg(#ffffff, $duplicate-svg, 'fill');
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.externalLinkButton {
  @include inline-svg(#ffffff, $external-link-svg, 'stroke');
  flex: 0 0 auto;
  padding: 10px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: $light-primary;
  width: 44px;
}

.deleteButton {
  @include inline-svg(#ffffff, $delete-svg, 'fill');
  flex: 0 0 auto;
  padding: 10px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: $light-primary;
  width: 44px;
}

.settingsButton {
  padding: 10px;
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: $light-primary;
  width: 44px;
  height: 44px;
}

.settingsButton:before {
  @include inline-svg(#ffffff, $settings-svg, 'stroke');
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.defaultButton {
  -webkit-font-smoothing: antialiased;
  border: 1px solid;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', 'sans-serif',
    '-apple-system', 'BlinkMacSystemFont';
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 1.143em;
  outline: none;
  padding: 6px 12px 4px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  background-color: $light-primary;
  color: #fff !important;
  border: none;
}

.workflowButton:hover,
.editButton:hover,
.editButton2:hover,
.addButton:hover,
.duplicateButton:hover,
.externalLinkButton:hover,
.deleteButton:hover,
.settingsButton:hover,
.defaultButton:hover {
  background-color: $light-primary-hover;
}

.one_dark {
  .workflowButton,
  .editButton,
  .editButton2,
  .addButton,
  .duplicateButton,
  .externalLinkButton,
  .deleteButton,
  .settingsButton,
  .defaultButton {
    background-color: $onedark-primary;
  }
  .workflowButton:hover,
  .editButton:hover,
  .editButton2:hover,
  .addButton:hover,
  .duplicateButton:hover,
  .externalLinkButton:hover,
  .deleteButton:hover,
  .settingsButton:hover,
  .defaultButton:hover {
    background-color: $onedark-primary-hover;
  }
}
